import fetcher from './fetcher';
import {
  GetSellerResponse,
  UploadImagesResponse,
  GetPlacesResponse,
  GetProductResponse,
  CreateSessionRequest,
  CreateSessionResponse,
  UpdateImageRequest,
  UpdateImageResponse,
  CreateOrderWebResponse,
  CreateOrderWebRequest,
  VerifyOrderOTPRequest,
  ResendOrderOTPRequest,
  CreateCartRequest,
  CreateCartResponse,
  CreateCartItemRequest,
  DeleteCartItemResponse,
  CreateCartItemResponse,
  UpdateSessionRequest,
  EstimateServiceFeeResponse,
  ResendOrderOTPResponse,
  GetCartResponse,
  CreateSessionItemResponse,
  Session as SessionSchema,
  UpdateSessionResponse,
  GetVouchersResponse,
  GetListVoucherTypesResponse,
  GetVoucherResponse,
  PutCartResponse,
  OrderTrackingResponse,
} from 'types/schema';
import qs from 'query-string';

const { API_URL } = process.env;

export interface GetSellerParams {
  id: string;
}

const Image = {
  uploadImages(files: File[]) {
    const body = new FormData();
    for (const file of files) {
      body.append('images[]', file, file.name);
    }

    return fetcher<Required<UploadImagesResponse>>(`${API_URL}/v1/images`, {
      method: 'POST',
      body,
    });
  },
  uploadBlogImages(blobs: Blob[]) {
    const body = new FormData();
    for (const blob of blobs) {
      body.append('images[]', blob);
    }

    return fetcher<Required<UploadImagesResponse>>(`${API_URL}/v1/images`, {
      method: 'POST',
      body,
      headers: {
        'content-type': 'application/json',
      },
    });
  },
  updateImage(imageId: number, body: UpdateImageRequest) {
    return fetcher<Required<UpdateImageResponse>>(
      `${API_URL}/v1/images/${imageId}`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  },
};

const City = {
  getCitiesURL: `${API_URL}/cities`,
  getDistrictsURL: `${API_URL}/districts`,
  getWardsURL: `${API_URL}/wards`,
  getCities() {
    return fetcher<Required<GetPlacesResponse>>(this.getCitiesURL);
  },
  getDistricts(cityID: string) {
    return fetcher<GetPlacesResponse>(
      `${this.getDistrictsURL}?city_id=${cityID}`,
    );
  },
  getWards(districtID: string) {
    return fetcher<Required<GetPlacesResponse>>(
      `${API_URL}/wards/?district_id=${districtID}`,
    );
  },
};

const Products = {
  getProductURL: `${API_URL}/v1/w/products`,
  getProductBySlug(slug: string, headers?: HeadersInit) {
    return fetcher<Required<GetProductResponse>>(
      `${this.getProductURL}/${slug}`,
      { headers },
    );
  },
};

const Session = {
  getSessionURL: `${API_URL}/v1/w/products/{slug}/{sessions}/{sharing_id}`,
  getSession(
    {
      productSlug,
      sessionId,
    }: {
      productSlug: string;
      sessionId: string;
    },
    headers?: HeadersInit,
  ) {
    return fetcher<Required<CreateSessionResponse>>(
      `${API_URL}/v1/w/products/${productSlug}/sessions/${sessionId}`,
      { headers },
    );
  },
  createSession(productSlug: string, body: CreateSessionRequest) {
    return fetcher<Required<CreateSessionResponse>>(
      `${API_URL}/v1/w/products/${productSlug}/sessions`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  },
  updateVariants({
    body,
    productSlug,
    sessionId,
  }: {
    sessionId: string;
    productSlug: string;
    body: UpdateSessionRequest;
  }) {
    return fetcher<Required<CreateSessionResponse>>(
      `${API_URL}/v1/w/products/${productSlug}/sessions/${sessionId}`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  },
  createImage({
    imageId,
    productSlug,
    sessionId,
    metadata,
  }: {
    productSlug: string;
    sessionId: string;
    imageId: number;
    metadata?: Record<string, any>;
  }) {
    return fetcher<Required<CreateSessionItemResponse>>(
      `${API_URL}/v1/w/products/${productSlug}/sessions/${sessionId}/items`,
      {
        method: 'POST',
        body: JSON.stringify({ image_id: imageId, metadata }),
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  },
  removeImage({
    sessionItemId,
    productSlug,
    sessionId,
  }: {
    sessionItemId: number;
    productSlug: string;
    sessionId: string;
  }) {
    return fetcher(
      `${API_URL}/v1/w/products/${productSlug}/sessions/${sessionId}/items/${sessionItemId}`,
      {
        method: 'DELETE',
      },
    );
  },
  updateSession({
    sessionId,
    productSlug,
    body,
  }: {
    sessionId: string;
    productSlug: string;
    body: SessionSchema;
  }) {
    return fetcher<Required<UpdateSessionResponse>>(
      `${API_URL}/v1/w/products/${productSlug}/sessions/${sessionId}`,
      {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  },
};

const Orders = {
  createOrderURL: `${API_URL}/v1/orders`,
  getOrderURL: `${API_URL}/v1/orders/:order_sharing_id/tracking`,
  createOrder(body: CreateOrderWebRequest) {
    return fetcher<Required<CreateOrderWebResponse>>(this.createOrderURL, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'content-type': 'application/json',
      },
    });
  },
  getOrder(order_sharing_id: string) {
    return fetcher<Required<OrderTrackingResponse>>(
      `${API_URL}/v1/orders/${order_sharing_id}/tracking`,
    );
  },
};

const OTP = {
  verifyOTPUrl: `${API_URL}/v1/otp/verify/o`,
  resendOTPUrl: `${API_URL}/v1/otp/retry/o`,
  verifyOTP(body: VerifyOrderOTPRequest) {
    return fetcher<Required<CreateOrderWebResponse>>(
      `${API_URL}/v1/otp/verify/o`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  },
  resendOTP(body: ResendOrderOTPRequest) {
    // FIXME: wait backend to update type definition
    return fetcher<Required<ResendOrderOTPResponse>>(
      `${API_URL}/v1/otp/retry/o`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  },
};

const Cart = {
  createCart(body: CreateCartRequest) {
    return fetcher<Required<CreateCartResponse>>(`${API_URL}/v1/w/carts`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'content-type': 'application/json',
      },
    });
  },
  getCartDetail(id: string) {
    return fetcher<Required<CreateCartResponse>>(`${API_URL}/v1/w/carts/${id}`);
  },
  getServiceFee({
    sharingId,
    voucherId,
  }: {
    sharingId: string;
    voucherId?: number;
  }) {
    const queryString = qs.stringify(
      {
        voucher_ids: voucherId ? [voucherId] : undefined,
      },
      { arrayFormat: 'bracket' },
    );

    return fetcher<Required<EstimateServiceFeeResponse>>(
      `${API_URL}/v1/w/carts/${sharingId}/fee?${queryString}`,
    );
  },
  addItem(id: string, body: CreateCartItemRequest) {
    return fetcher<Required<CreateCartItemResponse>>(
      `${API_URL}/v1/w/carts/${id}/items`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  },
  removeItem({ sharingId, itemId }: { sharingId: string; itemId: string }) {
    return fetcher<Required<DeleteCartItemResponse>>(
      `${API_URL}/v1/w/carts/${sharingId}/items/${itemId}`,
      {
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  },
  lockCart(id: string) {
    return fetcher<Required<GetCartResponse>>(
      `${API_URL}/v1/w/carts/${id}/lock`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  },
  unlockCart(id: string) {
    return fetcher<Required<GetCartResponse>>(
      `${API_URL}/v1/w/carts/${id}/unlock`,
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  },
  updateCart({
    sharingId,
    voucherId,
  }: {
    sharingId: string;
    voucherId: string | null;
  }) {
    const queryString = qs.stringify(
      {
        voucher_id: voucherId,
      },
      { arrayFormat: 'bracket' },
    );

    return fetcher<Required<PutCartResponse>>(
      `${API_URL}/v1/w/carts/${sharingId}?${queryString}`,
      {
        method: 'PUT',
        headers: {
          'content-type': 'application/json',
        },
      },
    );
  },
};

const Seller = {
  getBySlugURL: `${API_URL}/v1/w/sellers`,
  getBySlug(slug: string, headers?: HeadersInit) {
    return fetcher<Required<GetSellerResponse>>(
      `${this.getBySlugURL}/${slug}`,
      { headers },
    );
  },
};

const Voucher = {
  getVouchersURL: `${API_URL}/v1/w/vouchers`,
  getVoucherTypesURL: `${API_URL}/voucher-types`,
  getVouchers({ search, sharingId }: { search: string; sharingId: string }) {
    const queryString = qs.stringify(
      {
        cart_sharing_id: sharingId,
        search,
      },
      { arrayFormat: 'bracket' },
    );

    return fetcher<Required<GetVouchersResponse>>(
      `${this.getVouchersURL}?${queryString}`,
    );
  },
  getVoucherTypes() {
    return fetcher<Required<GetListVoucherTypesResponse>>(
      `${API_URL}/voucher-types`,
    );
  },
  getVoucher({ id }: { id: string }) {
    return Number(id) > 0
      ? fetcher<Required<GetVoucherResponse>>(`${API_URL}/v1/w/vouchers/${id}`)
      : {};
  },
};

const APIs = {
  Image,
  City,
  Products,
  Session,
  Orders,
  Cart,
  OTP,
  Seller,
  Voucher,
};

export default APIs;
