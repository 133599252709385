/* eslint-disable no-console */
import * as Sentry from '@sentry/node';

import { CaptureContext } from '@sentry/types';

const sentryDns = process.env.NEXT_PUBLIC_SENTRY_DSN;

export function captureException(err: Error, context?: CaptureContext) {
  if (!!sentryDns) {
    Sentry.captureException(err, context);
  } else {
    console.error(err);
  }
}

export function captureMessage(msg: string, context?: CaptureContext) {
  if (!!sentryDns) {
    Sentry.captureMessage(msg, context);
  } else {
    console.error(msg);
  }
}
