import fetch from 'isomorphic-unfetch';
import { captureException } from './logger';

export type FetcherError = Error & { response: Response };

let defaultHeaders = {};

// setDefaultHeaders set default header for every request
// WARNING: calling this on the server might causing invalid tracking value, need to test more
export function setDefaultHeaders(headers: HeadersInit) {
  defaultHeaders = headers;
}

function mergeRequestOptions({
  headers = {},
  ...options
}: RequestInit): RequestInit {
  return {
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    ...options,
  };
}

export default async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<JSON> {
  const res = await fetch(input, mergeRequestOptions(init || {}));
  if (res.ok) {
    return await res.json();
  }
  const error = new Error(res.statusText) as FetcherError;
  error.response = res;
  if (res.status !== 404) {
    captureException(error);
  }

  return Promise.reject(error);
}
